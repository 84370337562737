<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head has-background-dark">
        <Icon name="mdi:archive" class="mr-2 mt-1" color="white" size="1.5rem" />
        <p class="modal-card-title has-text-white">
          Sender Drive
        </p>

        <button class="delete is-large" aria-label="close" @click="ativo = false; emits('fechar')"></button>

      </header>
      <section class="modal-card-body has-background-light p-4" @click="focusOutHandler">
        <div id="empty"></div>


        <Loader v-if="pending" :styling="{ 'color': 'gray', 'font-size': '10px' }">
          <template #message>
            <p class="is-size-4">
              Carregando Arquivos...
            </p>
          </template>
        </Loader>
        <template v-else>
          <div class="columns is-multiline m-0  is-vcentered " id="arquivos">
            <div class="column is-12 px-2" id="info-armazenamento">
              <progress :class="`progress is-${statusArmazenamento}`" :value="percArmazenamento" max="100">

              </progress>
              <p>
                {{ armazenamento.usado.toFixed(2) }} MB de {{ armazenamento.limite }} MB usados ({{
                percArmazenamento}}%)
              </p>
            </div>

            <template v-if="arquivos.length > 0">
              <div class="column is-4-desktop px-2 is-clickable arquivo"
                :class="{ 'selecionado': arquivoSelecionado === arquivo }" @click="arquivoSelecionado = arquivo"
                v-for="(arquivo, index) in arquivos" :key="index">
                <div class="card">
                  <div class="card-image has-background-dark">
                    <img src="/assets/images/audio.jpeg" alt="" id="thumbnail" v-if="arquivo.extensao === '.ogg'">
                    <img src="/assets/images/pdf.jpeg" alt="" id="thumbnail" v-else-if="arquivo.extensao === '.pdf'">
                    <img :src="`data:image/PNG;base64,${arquivo.thumbnail}`" alt="" id="thumbnail" v-else>

                    <button class="button is-danger is-large " @click="modalApagarAtivo = !modalApagarAtivo"
                      v-if="arquivoSelecionado === arquivo" id="button-delete">
                      <Icon name="mdi:delete" class="icon" color="white" size="2rem" />
                    </button>

                  </div>

                  <div class="card-content pl-3 pt-3 pb-4">
                    <div class="content">
                      <p class="title is-7 mb-1 nome">
                        {{ arquivo.nome.split('.')[0] }}
                      </p>
                      <p class="subtitle is-7">
                        {{ arquivo.extensao.split('.')[1].toUpperCase() }} | {{ (arquivo.tamanho).toFixed(2) }} MB
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div class="column is-12 px-2" v-else>
              <div class="notification is-warning is-light">
                <h2 class="subtitle icon-text is-size-5-dekstop is-size-6-touch is-flex-wrap-nowrap">
                  <span class="icon">
                    <Icon name="mdi:alert" />
                  </span>
                  <span>
                    Você não tem arquivos salvos.
                  </span>
                </h2>
              </div>

            </div>

          </div>
        </template>

      </section>
      <footer class="modal-card-foot">

        <DropdownUpload @subindo="uploading = true" @sucesso="refresh(); refreshArmazenamento();
        useSuccessToast('Arquivo salvo com sucesso!')" @falha="uploading = false;" />

        <button class="button is-success has-text-white" :disabled="arquivoSelecionado == null"
          @click="selecionarArquivo()" v-if="isSelectMode">
          <span class="icon">
            <Icon name="mdi:archive-plus" />
          </span>
          <span>
            Selecionar
          </span>
        </button>

      </footer>
    </div>
  </div>

  <div class="modal is-active" v-if="modalApagarAtivo" id="modal-apagar">
    <div class="modal-background"></div>
    <div class="modal-content has-background-white">
      <section class="section">
        <p class=" has-text-left  is-size-5 fonte-1 has-text-dark">
          Deseja mesmo <span class="has-text-danger">APAGAR</span> o arquivo?
        </p>
        <hr class="">
        <div class="notification is-danger is-light ">
          <p class="has-text-left  is-size-6 fonte-1">
            <span>
              Essa ação irá apagar todas as mensagens que possuem esse arquivo.
            </span>
          </p>
        </div>
        <hr>
        <div class="field is-grouped">
          <div class="control is-expanded">
            <button class="button is-fullwidth has-text-weight-bold is-responsive is-normal"
              @click="modalApagarAtivo = !modalApagarAtivo">
              Cancelar
            </button>
          </div>
          <div class="control is-expanded">
            <button class="button is-fullwidth is-danger is-outlined is-normal is-responsive"
              @click="excluirArquivo(arquivoSelecionado.id)">
              <span>
                APAGAR MESMO ASSIM
              </span>
            </button>
          </div>
        </div>
      </section>
    </div>

  </div>
</template>

<script setup>

const emits = defineEmits(['arquivoSelecionado', 'fechar', 'arquivoApagado'])

const props = defineProps({
  isSelectMode: {
    type: Boolean,
    required: true,
    default: true,
  }
})

const { $api } = useNuxtApp()

const modalApagarAtivo = ref(false)
const arquivoSelecionado = ref(null)
const uploading = ref(false)
const statusArmazenamento = ref('success')
const percArmazenamento = ref(0)

const { data: arquivos, refresh, pending } = await useAsyncData('get-arquivos', () => $api('/api/arquivos/', { method: 'GET' }),
  { lazy: true, default: () => [] })

const { data: armazenamento, refresh: refreshArmazenamento } = await useAsyncData('get-armazenamento', () => $api('api/users/me'),
  {
    transform: (data) => ({
      usado: data.empresa.armazenamento,
      limite: data.empresa.limite_armazenamento
    })
  }

)

onMounted(() => {
  atualizarArmazenamento()
})

function selecionarArquivo() {
  emits('arquivoSelecionado', arquivoSelecionado.value);
  emits('fechar');
}

function excluirArquivo(id) {
  $api('/api/apagar-arquivo/', { method: 'post', body: { id: id } }).then(response => {
    modalApagarAtivo.value = false;
    arquivoSelecionado.value = null;
    emits('arquivoApagado', id);
    useSuccessToast('Arquivo apagado com sucesso!');
    refresh();
    refreshArmazenamento();
  })
}

function atualizarArmazenamento() {
  percArmazenamento.value = ((armazenamento.value.usado / armazenamento.value.limite) * 100).toFixed(2);

  if (percArmazenamento.value >= 70.00) {
    statusArmazenamento.value = 'danger';
  }
  else if (percArmazenamento.value >= 50.00) {
    statusArmazenamento.value = 'warning';
  } else {
    statusArmazenamento.value = 'success';
  }

  useAuthStore().setArmazenamentoUsado(percArmazenamento.value)
  useAuthStore().setArmazenamentoLimite(armazenamento.value.limite)
  useAuthStore().setStatusArmazenamento(statusArmazenamento.value)
}

watch(armazenamento, async (armazenamentoAntigo, novoArmazenamento) => {
  atualizarArmazenamento()
})

function focusOutHandler(event) {
  if (event.target.id === 'empty' || event.target.id === 'arquivos' || event.target.id === 'info-armazenamento') {
    arquivoSelecionado.value = null
  }
}

</script>

<style lang="scss" scoped>
.modal-card-body {
  position: relative;
  min-height: 60dvh;
  max-height: 60dvh;

}

#info-armazenamento {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;

  & progress {
    width: 45%;
    margin-bottom: 0px;
    border-radius: 2px;
    border: 1px solid grey;
    background-color: white;
  }
}

.modal-card-foot {
  display: flex;
  width: 100%;
  flex-shrink: 1;
  justify-content: space-between;
}

.modal-card {
  min-width: 50dvw !important;
}


@media screen and (max-width: 768px) {

  .modal-card,
  #modal-apagar .modal-content {
    max-width: 95dvw !important;
  }

  #info-armazenamento {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;

    & progress {
      width: 100%;
    }
  }
}


#modal-apagar .modal-content {
  //max-width: 20dvw;
  border-radius: 15px;
}

.card-image {
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 10px 10px 0 0;
}



.card-image img {
  height: 200px;
  width: 100%;
  object-fit: contain;
}


#button-delete {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
}

.arquivo:hover,
.selecionado {
  transform: scale(1.05);
}

.arquivo:hover #button-delete {
  opacity: 0.5;
}

#button-delete:hover {
  opacity: 1 !important;
}


#icon-tipo {
  position: absolute;
  bottom: 15px;
  right: 5px;
  background-color: white;
  border: 3px white solid;
  border-radius: 50%;
}

.nome {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#upload {
  position: absolute;
  left: 20;
}

.arquivo {
  position: relative;
  z-index: 12;
}

#arquivos {
  position: relative;
  width: auto;
  z-index: 10;
}

#empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  z-index: 10;
}

#arquivos:has(.selecionado) .arquivo:not(.selecionado) {
  opacity: 0.5;
}
</style>